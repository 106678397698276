import { SvgIcon } from '@/components/icon'
import WebsiteButton from '@/components/website-button/WebsiteButton'
import WebsiteTypography from '@/components/websiteTypography/WebsiteTypography'
import { scrollToSection } from '@/helpers/scrollToSection'
import { websiteRoutes } from '@/router/routes'
import { Space } from 'antd'
import { FC } from 'react'
import './Footer.less'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import fbIcon from './images/fb.png'
import sectionBg from './images/footer-bg.png'
import instaIcon from './images/instagram.png'
import linkedIcon from './images/lingedin.png'
import twitterIcon from './images/twitter-icon.png'
import logoWhite from './images/logo_company-white.png'

const { Text } = WebsiteTypography


const Footer: FC = () => {
  const navigate = useNavigate()
  const { website } = websiteRoutes

  const params = useParams()
  const currentOrgSlug = params.org

  const onPrivacyClick = () => {
    navigate(website.privacyPolicy)
  }
  const onTermsClick = () => {
    navigate(website.termsAndConditions)
  }

  const onSoftwareSolutionsClick = async ()=>{
    navigate(`/easetech${website.ourServices}`)
    await scrollToSection('software-solutions')
  }

  const onConsultingServicesClick = async ()=>{
    navigate(`/easetech${website.ourServices}`)
    await scrollToSection('consulting-services')
  }

  const onHomeClick = async ()=>{navigate(`/easetech${website.home}`)}

  return (
    <div className={'footer-section-wrapper'}>
      <div className={'footer-bg-wrapper'}>
        <img src={sectionBg} alt={''} />
      </div>
      <div className={'footer-section-content'}>
        <div className={'logo-block'}>
          <div className={'logo-wrapper'}>
            <img src={logoWhite} alt={''} />
          </div>
          <div className={'soc-buttons-block'}>
            <WebsiteButton btnType={'icon'} className={'soc-btn sm'}>
              <SvgIcon type={'footer-soc-btn'} />
              <img src={fbIcon} alt={''} />
            </WebsiteButton>
            <WebsiteButton btnType={'icon'} className={'soc-btn sm'}>
              <SvgIcon type={'footer-soc-btn'} />
              <img src={twitterIcon} alt={''} />
            </WebsiteButton>
            <WebsiteButton btnType={'icon'} className={'soc-btn'}>
              <SvgIcon type={'footer-soc-btn'} />
              <img src={instaIcon} alt={''} />
            </WebsiteButton>
            <WebsiteButton btnType={'icon'} className={'soc-btn'}>
              <SvgIcon type={'footer-soc-btn'} />
              <img src={linkedIcon} alt={''} />
            </WebsiteButton>
          </div>
          <div className={'terms-block'}>
            <WebsiteButton btnType={'text'} onClick={onTermsClick}>
              <Text color={'website-secondary'} size={'sm'}>{'Terms and conditions'}</Text>
            </WebsiteButton>
            <div className={'footer-divider'} />
            <WebsiteButton btnType={'text'} onClick={onPrivacyClick}>
              <Text color={'website-secondary'} size={'sm'}>{'Privacy policy'}</Text>
            </WebsiteButton>
          </div>
          <div className={'rights-reserved-block'}>
            <Text color={'website-secondary'} weight={'w300'} size={'md'} className={'rights-text'}>
              © {new Date().getFullYear()}
              <span>
                 {' easetech. '}
               </span>
              All Rights Reserved
            </Text>
          </div>
        </div>
        <div className={'content-block'}>
          <div className={'navigation'}>
            <div className={'footer-content-title'}>
              <Text weight={'w700'} size={'lg'}>{'Navigation'}</Text>
            </div>
            <WebsiteButton btnType={'text'} onClick={onHomeClick}>
              <Text weight={'w700'} color={'website-secondary'} size={'md'}>{'Home'}</Text>
            </WebsiteButton>
            <WebsiteButton btnType={'text'} onClick={() => scrollToSection('contact')}>
              <Text weight={'w700'} color={'website-secondary'} size={'md'}>{'Contact Us'}</Text>
            </WebsiteButton>
            <Space direction={'vertical'} size={'small'} style={{ paddingTop: '16px' }}>
              <WebsiteButton btnType={'ghost-white'} block>
                <Link to={`/${currentOrgSlug}${website.auth}/login`}>
                  <Text>
                    {'SIGN IN'}
                  </Text>
                </Link>
              </WebsiteButton>
              <WebsiteButton btnType={'primary'} block>
                <Link to={`/${currentOrgSlug}${website.auth}/client`}>
                  <Text>
                    {'REGISTER'}
                  </Text>
                </Link>
              </WebsiteButton>
            </Space>
          </div>

        </div>
        <div className={'content-block links-block'}>
          <div className={'footer-content-title'}>
            <Text weight={'w700'} size={'lg'}>{'Our Services'}</Text>
          </div>
          <WebsiteButton btnType={'text'} onClick={onSoftwareSolutionsClick}>
            <Text weight={'w700'} color={'website-secondary'} size={'md'}>{'Software Solution'}</Text>
          </WebsiteButton>
          <WebsiteButton btnType={'text'} onClick={onConsultingServicesClick}>
            <Text weight={'w700'} color={'website-secondary'} size={'md'}>{'Consulting Services'}</Text>
          </WebsiteButton>
        </div>
        <div className={'content-block'}>
          <div className={'footer-content-title'}>
            <Text weight={'w700'} size={'lg'}>{'Contact us'}</Text>
          </div>
          <div className={'contact-us-item'}>
            <Text size={'sm'} lh={'lh-lg'} color={'website-secondary'}>{'Phone:'}</Text>
            <a href={'tel:+0826996888'}>
              <Text weight={'w700'} size={'md'}>{'082 699 6888'}</Text>
            </a>
          </div>

          <div className={'contact-us-item'} style={{ paddingTop: '12px' }}>
            <Text size={'sm'} color={'website-secondary'}>{'Email:'}</Text>
            <a href={'mailto:info@easetech.co.za'}>
              <Text weight={'w300'} size={'md'} color={'orange'}>{'info@easetech.co.za'}</Text>
            </a>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Footer
