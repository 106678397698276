import { SvgIcon } from '@/components/icon'
import { Button } from 'antd'
import { FC, useEffect, useState } from 'react'
import './BackToTopButton.less';

const BackToTopButton: FC = () => {

  const [backToTopButton, setBackToTopButton] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', ()=>{
      if (window.scrollY > 700){
        setBackToTopButton(true)
      } else {
        setBackToTopButton(false)
      }
    })
  }, [])

  const scrollUp = ()=>{
    window.scrollTo(
      {
        top: 0,
        behavior: 'smooth',
      },
    )
  }

  return (
    <>
      {/*{backToTopButton && (*/}
        <Button
          className={`back-to-top-button ${backToTopButton ? 'visible' : ''}`}
          onClick={scrollUp}>
          <SvgIcon type={'arrow-scroll-up'} />
        </Button>
      {/*)}*/}
    </>
  );
}

  export default BackToTopButton