import { useAuth } from '@/components/context/AuthProvider'
import { USER_TOKEN } from '@/helpers/auth'
import BackToTopButton from '@/pages/website-landing/components/back-to-top-button/BackToTopButton'
import Footer from '@/pages/website-landing/components/footer/Footer'
import Header from '@/pages/website-landing/components/header/Header'
import { EASETECH_COMPANY } from '@/utilities/global-variables'
import { Layout } from 'antd'
import { FC, Suspense } from 'react'
import { Outlet, useParams } from 'react-router'
import '../../styles/tooltip.less'
import HomeHero from '@/pages/website-landing/home/sections/home-hero/HomeHero'

import './index.less'

const WebsiteLayout: FC = () => {
  const params = useParams()
  const currentOrgSlug = params.org
  const { user } = useAuth()
  if (!user) {
    localStorage.removeItem(USER_TOKEN)
  }
  return (
    <Layout>
      <BackToTopButton />
      <Header />
      <Suspense fallback={<HomeHero loading={true} />}>
        <Outlet />
      </Suspense>
      {currentOrgSlug === EASETECH_COMPANY && (
        <Footer />
      )}
    </Layout>
  )
}

export { WebsiteLayout }
